export default function Button(props: ButtonProps) {
  const { title, disabled, isLoading, onClick } = props;

  return (
    <button 
      className='button separate-top' 
      disabled={isLoading || disabled}
      onClick={onClick}
    >
      { isLoading ?
        'Cargando...'
      :
        title
      }
    </button>
  );
}

interface ButtonProps {
  title: string;
  disabled?: boolean;
  isLoading: boolean;
  onClick?: () => void;
}

