import { useState } from "react";
import config from "../config/config";
import { FieldInterface } from "../hooks/useField";
import Button from "./Button";
import TextIpunt from "./TextInput";

export default function EmailForm(props: EmailFormProps) {
  const inputField = props.emailField;
  const [isLoading, setIsLoading] = useState(false);

  const makeApiRequest = () => {
    const baseUrl = config.api.URL;
    setIsLoading(true);
    fetch(`${baseUrl}/password_resets`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        password_reset: {
          email: inputField.value,
        }
      }),
    }).then((response) => {
      if (response.ok) {
        props.onSuccess();
      } else {
        if (response.status === 404) {
          alert('Usuario no encontrado por el email indicado. Por favor, revisa que esté bien escrito.');
        } else {
          alert('Error: ' + response.statusText);
        }
        setIsLoading(false);
      };
    }).catch((error: Error) => {
      alert('Error ' + error.message);
      setIsLoading(false);
    });
  }

  const disabled = !Boolean(inputField.value);

  const handleOnClick = () => {
    makeApiRequest();
  }

  return (
    <div>
      <p>Te enviaremos un código para restablecer tu contraseña</p>
      <TextIpunt {...inputField} placeholder='Dirección de correo' />
      <Button title="Continuar" onClick={handleOnClick} isLoading={isLoading} disabled={disabled} />
    </div>  
  );
}

interface EmailFormProps {
  onSuccess: () => void;
  emailField: FieldInterface;
}