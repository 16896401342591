import { useState } from "react";
import useField from "../hooks/useField";
import repository from "../repositories/RealmAuthRepository";
import Button from "./Button";
import TextIpunt from "./TextInput";

export default function ResetPasswordForm(props: {
  urlString: string;
  onSuccess: () => void;
}) {
  const passwordField = useField({ type: 'password' });
  const passwordConfirmationField = useField({ type: 'password' });
  const [isLoading, setIsLoading] = useState(false);
  
  const token = props.urlString.split("&")[0];
  const tokenId = props.urlString.split("&")[1];

  const handleOnClick = () => {

    const errorMessages: { [key: number]: string; } = {
      404: "El código introducido ya se ha utilizado o ha expirado.",
      400: "La contraseña debe tener 6 o más caracteres.",
    };

    setIsLoading(true);
    repository.resetPassword(passwordField.value, token, tokenId)
    .then(() => {
      props.onSuccess();
    }).catch((error) => {
      let errorMessage = error.message;
      if (error.statusCode) {
        if (errorMessages.hasOwnProperty(error.statusCode)) {
          errorMessage = errorMessages[error.statusCode];
        } else {
          errorMessage = "Error: " + error.error;
        }
      }
      alert(errorMessage);
      console.log(error.errorCode, error.statusCode, JSON.stringify(error));
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const disabled = !(Boolean(passwordField.value) && (passwordField.value === passwordConfirmationField.value));
  
  return (
    <div className="turnario-new-app">
      <p>
        <img className="logo-img" src="https://cdn.shopify.com/s/files/1/0511/9661/files/logo_turnario_app_long.png?v=1656588966" alt="Logo Turnario App" />
      </p>
      <p>Introduce la nueva contraseña. </p>
      <TextIpunt placeholder='Nueva contraseña' {...passwordField} />
      <TextIpunt placeholder='Confirmar nueva contraseña' {...passwordConfirmationField}/>
      <Button title="Restablecer contraseña" onClick={handleOnClick} isLoading={isLoading} disabled={disabled} />
    </div>
  );
}