const productionApiUrl = process.env.REACT_APP_API_URL;
const apiUrl = process.env.NODE_ENV === "production" ? productionApiUrl : "http://localhost:3001"

const config = {
  realm: {
    appId: process.env.REACT_APP_REALM_APP_ID || "",
  },
  api: {
    URL: apiUrl,
  },
}

export default config;