import { ChangeEvent, useState } from "react";

export default function useField({ type }: { type: string }): FieldInterface {
  const [value, setValue] = useState<string>('');
  const onChange = (e: InputChangeEvent) => { setValue(e.target.value); }

  return ({
    value, 
    onChange,
    type,
  });

}

type InputChangeEvent = ChangeEvent<HTMLInputElement>;

export interface FieldInterface {
  value: string;
  type: string;
  onChange: (e: InputChangeEvent) => void;
}