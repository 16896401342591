import { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import CodeForm from './components/CodeForm';
import EmailForm from './components/EmailForm';
import PasswordForm from './components/PasswordForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import SuccessConfirmation from './components/SuccessConfirmation';
import useField from './hooks/useField';

function App() {
  const [step, setStep] = useState(0);
  const emailField = useField({ type: 'email' });
  const emailCodeField = useField({ type: 'number' });

  const [urlString, setUrlString] = useState('');

  useLayoutEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const tokenId = params.get("tokenId");
    if (token && tokenId) {
      setUrlString(`${token}&${tokenId}`);
    }
  }, []);

  useEffect(() => {
    if (Boolean(urlString)) {
      setStep(2);
    }
  }, [urlString]);

  const handleOnSuccess = () => {
    setStep(step + 1);
  }
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-header-content">
          <h1>Turnario</h1>
          <h2>Restablecer contraseña</h2>
        </div>
      </header>
      <main className="App-main">
        { step === 0 && 
          <EmailForm onSuccess={handleOnSuccess} emailField={emailField} />
        }
        { step === 1 && 
          <CodeForm onSuccess={handleOnSuccess} email={emailField.value} emailCodeField={emailCodeField} />
        }
        {
          step === 2 && (
            urlString ? (
              <ResetPasswordForm urlString={urlString} onSuccess={handleOnSuccess} />
            ) : (
              <PasswordForm onSuccess={handleOnSuccess} email={emailField.value} emailCode={emailCodeField.value} />
            )
          )
        }
        {
          step === 3 &&
          <SuccessConfirmation />
        }
      </main>
      <footer className='App-footer'>
        <p>TURNARIO es una marca registrada de ENFERMERA EN APUROS S.L. B76740190</p>
        <p>{ new Date().getFullYear() } © Todos los derechos reservados</p>
      </footer>
    </div>
  );
}

export default App;
