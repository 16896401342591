import app from "./realmApp";

const repository = {
  sendPasswordResetEmail(email: string) {
    return app.emailPasswordAuth.callResetPasswordFunction({ 
      email, 
      password: "newPassw0rd",
    }, [true]); // ask for email confirmation
  },
  resetPassword(newPassword: string, token: string, tokenId: string) {
    return app.emailPasswordAuth.resetPassword({
      password: newPassword, 
      token, 
      tokenId,
    }); 
  },
};

export default repository;