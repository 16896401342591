import { useState } from "react";
import config from "../config/config";
import useField from "../hooks/useField";
import Button from "./Button";
import TextIpunt from "./TextInput";

export default function PasswordForm(props: PasswordFormProps) {
  const passwordField = useField({ type: 'password' });
  const passwordConfirmationField = useField({ type: 'password' });
  const [isLoading, setIsLoading] = useState(false);

  const makeApiRequest = (emailCode: string, email: string) => {
    const baseUrl = config.api.URL;
    setIsLoading(true);
    fetch(`${baseUrl}/password_resets/${emailCode}`, {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        email,
        user: {
          password: passwordField.value,
          password_confirmation: passwordConfirmationField.value,
        }
      }),
    }).then((response) => {
      console.log(response);
      if (response.ok) {
        props.onSuccess();
      } else {
        if (response.status === 404) {
          alert('Usuario no encontrado por el email indicado. Por favor, revisa que esté bien escrito.');
        } else if (response.status === 401) {
          alert('El código introducido ya se ha utilizado o ha expirado.');
        } else {
          alert('Error: ' + response.statusText);
        }
        setIsLoading(false);
      };
    }).catch((error: Error) => {
      alert('Error ' + error.message);
      setIsLoading(false);
    });
  }

  const disabled = !(Boolean(passwordField.value) && (passwordField.value === passwordConfirmationField.value));

  const handleOnClick = () => {
    makeApiRequest(props.emailCode, props.email);
  }

  return (
    <div>
      <p>Introduce la nueva contraseña. </p>
      <TextIpunt placeholder='Email' value={props.email} readOnly />
      <TextIpunt placeholder='Nueva contraseña' {...passwordField} />
      <TextIpunt placeholder='Confirmar nueva contraseña' {...passwordConfirmationField}/>
      <Button title="Restablecer contraseña" onClick={handleOnClick} isLoading={isLoading} disabled={disabled} />
    </div>
  );
}

interface PasswordFormProps {
  email: string;
  emailCode: string;
  onSuccess: () => void;
}