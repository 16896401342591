import { useState } from "react";
import config from "../config/config";
import { FieldInterface } from "../hooks/useField";
import Button from "./Button";
import TextIpunt from "./TextInput";

export default function CodeForm(props: CodeFormProps) {
  const inputField = props.emailCodeField;
  const [isLoading, setIsLoading] = useState(false);

  const makeApiRequest = (emailCode: string, email: string) => {
    const baseUrl = config.api.URL;
    setIsLoading(true);
    fetch(`${baseUrl}/password_resets/${emailCode}/validate`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    }).then((response) => {
      if (response.ok) {
        props.onSuccess();
      } else {
        if (response.status === 404) {
          alert('Usuario no encontrado por el email indicado. Por favor, revisa que esté bien escrito.');
        } else if (response.status === 401) {
          alert('El código introducido ya se ha utilizado o ha expirado.');
        } else {
          alert('Error: ' + response.statusText);
        }
        setIsLoading(false);
      };
    }).catch((error: Error) => {
      alert('Error ' + error.message);
      setIsLoading(false);
    });
  }

  const disabled = !Boolean(inputField.value);

  const handleOnClick = () => {
    makeApiRequest(inputField.value, props.email);
  }

  return (
    <div>
      <p>Hemos enviado un código a tu dirección de correo electrónico. Escribe aquí ese código. </p>
      <TextIpunt {...inputField} placeholder='Escribe el código' />
      <Button title="Validar" onClick={handleOnClick} isLoading={isLoading} disabled={disabled} />
    </div>  
  );

}

interface CodeFormProps {
  email: string;
  emailCodeField: FieldInterface;
  onSuccess: () => void;
}